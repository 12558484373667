import WorkspaceData from '../WorkspaceData';
import router from '../../router';

export default class OnvistaWorkspace extends WorkspaceData {
  constructor() {
    super();
    this.customerId = 'onvista';
    // cache der zuletzt gewählten Aktien (für den input oben) - idealerweise in der Reihenfolge, in der sie zuletzt genutzt worden
    // Sollte auf eine sinnvolle Maximalanzahl (z.B. 10) limitiert werden.
    this.workspace.stocks = [];
    // cache der zuletzt gewählten Datumsangaben (für den input oben) - z.B. ISO Formatiert (?) - idealerweise immer sortiert
    // Wie die Stocks auf z.B. 10 Einträge limitiert
    this.workspace.dates = [];
    console.log('Custom workspace loaded: onvista');
  }

  getRenderAllowance() {
    return this.workspace.stocks[0]?.isin && this.workspace.dates[0];
  }

  getRenderQuery() {
    if(this.getRenderAllowance()) {
      return `isin=${this.workspace.stocks[0]?.isin}&date=${this.workspace.dates[0]}&seed=${this.workspace.seed}&customerCode=${this.customerId}`;
    }
    this.dispatchEvent( {
      type: 'toast-msg',
      message: {
        type: 'warning',
        text:`missing stock and/or date`,
      },
    });

    return false;
  }

  onRenderResponse404() {
    this.dispatchEvent( {
      type: 'toast-msg',
      message: {
        type: 'warning',
        text: `Wir können leider keine Daten für ${this.workspace.stocks[0].isin}(ISIN) | ${this.workspace.dates[0]}(Datum) finden`,
      },
    });
  }

  async getStockList(value) {
    const response = await fetch(`/api/v1/onvista/instruments?query=${value}`, {
      method: 'GET',
    });

    if(response.status === 200) {
      return response.json();
    }

    if(response.status === 404) {
      this.dispatchEvent( {
        type: 'toast-msg',
        message: {
          type: 'warning',
          text: 'Kein Ergebnis',
        },
      });
    }

    if(response.status === 401) {
      await router.push({name:'Login'});
    }
  }

  async signalSearch(signalArray) {
    if(signalArray.length > 0) {
      let selectors = {
        'selectors': signalArray
      }
      const spinner = document.getElementById("spinner");
      spinner.removeAttribute('hidden');

      let response = await fetch('/api/v1/onvista/instrumentsBySelectors',
          {
            method: 'POST',
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(selectors)
          });

      let result = await response.json();
      spinner.setAttribute('hidden', '');

      switch (response.status) {
        case 200:
          this.workspace.dates.unshift(result.date);
          this.workspace.stocks.unshift(result.security);
          this.renderTemplate();
          break;
        case 401:
          await router.push({name:'Login'});
          break;
        default:
          this.dispatchEvent( {
            type: 'toast-msg',
            message: {
              type: 'error',
              text: `(${response.status}): ${response.statusText}`,
            },
          });
      }
    }
  }

  addStockToList(name, isin) {
    const stock = {
      isin,
      name,
    }
    if (this.workspace.stocks.find(stock => stock.isin === isin)) {
      let pos = this.workspace.stocks.findIndex(stock => stock.isin === isin);
      this.workspace.stocks.splice(pos, 1);
    }
    this.workspace.stocks.unshift(stock);
    if (this.workspace.stocks.length > 15) {
      this.workspace.stocks.pop();
    }
    this.renderTemplate();
  }

  addDateToList(date) {
    if (this.workspace.dates.includes(date)) {
      let pos = this.workspace.dates.indexOf(date);
      this.workspace.dates.splice(pos, 1);
    }
    this.workspace.dates.unshift(date);
    if (this.workspace.dates.length > 15) {
      this.workspace.dates.pop();
    }
    this.renderTemplate();
  }

  async requestDate() {
    if (this.workspace.stocks.length > 0) {
      const request = await fetch(`/api/v1/onvista/lastValidDate?isin=${this.workspace.stocks[0].isin}`);
      const status = request.status;

      if (status === 200)  {
        const data = await request.json();
        this.addDateToList(data.date)
      }

      if (status === 401) {
        await router.push({name:'Login'});
      }

      if (status === 404) {
        this.dispatchEvent( {
          type: 'toast-msg',
          message: {
            type: 'warning',
            text: 'Kein Datum in den letzten 31 Tagen für diese Aktie gefunden',
          },
        });
      }
    } else {
      this.dispatchEvent( {
        type: 'toast-msg',
        message: {
          type: 'warning',
          text: 'Wählen Sie eine Aktie aus',
        },
      });
    }
  }

  feedbackError() {
    this.dispatchEvent( {
      type: 'toast-msg',
      message: {
        type: 'warning',
        text: "Bitte wählen Sie eine Aktie und ein Datum!",
      },
    });

  }
}